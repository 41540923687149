#root{
  height: 100%;
}

body {
  font-size: 16px !important;
}

* {
  font-family: "PingFang SC";
}

.header-scroll {
  .logo-slide {
    transform: translateY(0px);
    transition: transform 0.2s ease-in-out;
  }
}

.header-scroll-active {
  background: rgba(255,255,255,0.9);
  backdrop-filter: blur(24px);
  .logo-slide {
    transform: translateY(-36px);
  }
}

.header-slide-ani {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  box-shadow: 0 2px 4px #e5e5e5;
}
.show-header {
  max-height: 180px;
}

.header-menu {
  display: none;
  position: absolute;
  top: 35px;
  width: 110px;
  padding-top: 10px;
  .container {
    padding: 8px 0;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 12px 32px 0 rgba(194,199,218,1);
    .logout {
      line-height: 30px;
      padding: 0 12px;
      height: 30px;
      cursor: pointer;
    }
  }
}
.logout:hover {
  background: rgba(239,242,244,1);
}

.pc-user-info:hover .header-menu {
  display: block !important;
}




.mobile-header-bg {
  background: rgba(255,255,255,0.95);
  backdrop-filter: blur(24px);
}

.footer-warpper {
  background: linear-gradient(270deg, rgba(245,248,252,1) 0%, rgba(245,248,252,0.5) 100%);
  .text-sm {
    color: #797979;
  }
}



/* 按钮样式 */
.default-btn:hover {
  border: 1px solid rgba(215,222,227,1);
  background: rgba(215,222,227,0.2);
}
.default-btn:focus, .default-btn:active {
  border: 1px solid rgba(215,222,227,1);
  background: rgba(215,222,227,0.4);
}

.login-btn {
  background: linear-gradient(-52deg, rgba(16,214,188,1) 0%, rgba(41,177,253,1) 100%);
  color: #fff;
}

.login-btn:hover {
  background: linear-gradient(-52deg, rgba(34,220,196,1) 0%, rgba(102,200,255,1) 100%);
}

.login-btn:active {
  background: linear-gradient(-52deg, rgba(16,196,172,1) 0%, rgba(24,158,233,1) 100%);
}

.global-btn {
  color: #fff;
  padding: 0px 30px;
  line-height: 36px;
  border-radius: 2px;
  height: 36px;
  display: inline-block;
  min-width: 100px;
  text-align: center;
  border: 1px solid rgba(215,222,227,0);
  margin: 0 5px;
  font-size: 14px;
  cursor: pointer;
}

.global-gray-btn{
  background: #fff;
  color: #000;
  padding: 0px 30px;
  line-height: 36px;
  border-radius: 2px;
  height: 36px;
  display: inline-block;
  min-width: 100px;
  text-align: center;
  border: 1px solid rgba(215,222,227,1);
  margin: 0 5px;
  font-size: 14px;
  cursor: pointer;
  &:hover{
    background: rgba(255,255,255,0.9);
  }
  &:active,&:focus{
    background: rgba(255,255,255,0.8);
  }
}

.default-btn-menu:focus, .default-btn-menu:active {
  background: rgba(215,222,227,0.4);
}

::-webkit-scrollbar {
  width: 9px;
  height: 5px;
  overflow: hidden;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  min-height: 28px;
  background-color: #bababa;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c5c5c5;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

.input-wrapper {
  border-radius: 2px;
  opacity: 1;
  border: 0 solid rgba(201,205,210,1);
  background: #eff3f6;
  input {
    background: transparent;

    outline: 0;
  }
  
}

.form-hr {
  color: #999;
  font-size: 14px;
  line-height: 20px;
  margin: 20px 0;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    padding: 0 20px;
    position: relative;
    z-index: 2;
  }
}
.form-hr:after {
  content: "";
  display: block;
  flex: 1;
  height: 1px;
  background-color: #e5e5e5;
}
.form-hr:before {
  content: "";
  display: block;
  flex: 1;
  height: 1px;
  background-color: #e5e5e5;
}